import React from 'react'
import { useTranslation } from 'react-i18next';

const Footer = () => {    
    const [t, i18n]      = useTranslation("global");
    
  return (
    <div>
                      <div class="zona_footer">
     
                      </div>
                        <style>

                                {`  


                                `}

                        </style>  

    </div>
  )
}

export default Footer