import React from 'react'
import { useTranslation } from 'react-i18next';
import logo_cambia from './../assets/images/par_token.svg';
const Limite = () => {
    
    const [t, i18n]      = useTranslation("global");
  return (    <div>

                        

             <div className="zona_limite">
                      
                      <div className='row'>
                             <div className='col-md-6  col-12 text-center'>
                                       
                                         <img src={logo_cambia} className="img-fluid img_li"/>

                             </div>
                             <div className='col-md-6  col-12 text-right the_ri'>
                                                
                                          <div className="text_main">Limite de</div>
                                            <div className="text_main">Cambio</div>
                                            <div className="line_main  right_pu"></div>

                                            <div className="leyenda">
                                                     <p className="text-right">
                                                     Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                                                     nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. 
                                                     </p>
                                            </div>

                             </div>
                      </div>

                      <div className='text-center sepa'>
                           <button className="btn btn_orange text-bold "> {t('herecharge')} </button>
                      </div>
                      
             </div>


             <style>

                     {`  

                           .zona_limite{
                                 margin-top:80px;
                                  background:#f3fbf3;
                                  padding:50px;
                                  border-radius:24px;
                           }
                                  
                           .img_li{
                               width:80%;
                           }




                     `}

              </style>  

  </div>
  )
}
export default Limite