import React from 'react'
import { useTranslation } from 'react-i18next';
const Compra = () => {
    
    const [t, i18n]      = useTranslation("global");
  return (
    <div>
                      <div class="zona_compra">

                                  <div className="row">

                                            <div className='col-md-6 col-12 head_left'>
                                                        <div className="titulo_main">
                                                              <div className="text_main">{t("buy")}</div>
                                                              <div className="text_main">{t("yourcrytp")}</div>
                                                            <div className="line_main"></div>
                                                        </div>

                                                        <div className='text_resena'>
                                                              {t('main_text')} 
                                                        </div>                  
                                            </div> 
                                    </div>

                                    <div className='text-center sepa'>
                                            <button className="btn btn_orange text-bold "> {t('herebuy')} </button>
                                    </div>


     
                      </div>
                        <style>

                                {`  
                                          .zona_compra{
                                          margin-top:80px;
                                             background:#f3fbf3;
                                            padding:50px;
                                            border-radius:24px;
                                          }

                                          .zona_compra .sepa{
                                             margin-top:100px;
                                          }

                                `}

                        </style>  

    </div>
  )
}

export default Compra