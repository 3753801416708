import React from 'react'

const Cuerpo    = () => {
  return (
    <div>

            <div className='mejor_cripto'>

                    <div className='titulo'>
                             <div className="text_main"> Tu mejor</div>
                             <div className="text_main"> Cambio de Crypto</div>
                             <div className="line_main"></div>
                    </div>
                    
                    <div className='leyenda'>
                             <div className="row">
                                    <div className='col-md-4 col-12'>
                                       <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                      <div className="number_l">1</div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Elegir Moneda</h6>
                                                    <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                     </span>
                                                </div>
                                        </div>

                                    </div>
                                    <div className='col-md-4 col-12'>
                                          <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                     <div className="number_l">2</div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Hacer depósito</h6>
                                                    <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                     </span>
                                                </div>
                                            </div>

                                    </div>
                                    <div className='col-md-4 col-12'>

                                            <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                         <div className="number_l">3</div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6>Obtén tus monedas</h6>
                                                        <span className="text_min"> This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                         </span>
                                                    </div>
                                            </div>

                                    </div>
                             </div>
                                    
                           

                    </div>

            </div>

                <style>

                {`  
                          .mejor_cripto{
                              background:#f3fbf3;
                              padding:50px;
                              padding-bottom:100px;
                              padding-top:120px;
                          }

                          .mejor_cripto h6{
                             margin-bottom:2px;
                             padding-bottom:2px;
                          }

                          .leyenda{
                               margin-top:50px;
                          }

                          .number_l{
                               line-height:0.6;
                               font-size:102px;
                               font-weight:bold;
                               padding:0 !important;
                          }
                               .text_min{
                                  margin-top:-10px;
                                  font-size:10px;
                                  line-height:0;
                                  text-align:justify;
                                   
                               }
                              

                              @media only screen and (max-width : 900px) {

                                        .mejor_cripto{
                                            padding:20px;
                                        }
                                            .leyenda .d-flex{
                                                margin-top:40px;
                                                margin-bottom:10px;
                                            }

                                    
    
                            }
                     `}  

                        
                

                </style>

    </div>
  )
}

export default Cuerpo