import React from 'react'
import { useTranslation } from 'react-i18next';
const Beneficio = () => {
    
    const [t, i18n]      = useTranslation("global");
  return (
    <div>

                          <div className="zona_beneficio">
                                    <div className="row">

                                            <div className='col-md-6 col-12 head_left'>
                                                          <div className="titulo_main">
                                                                <div className="text_main">Aumenta</div>
                                                                <div className="text_main">Tus beneficios</div>
                                                              <div className="line_main"></div>
                                                          </div>

                                                          <div className='text_resena'>
                                                                {t('main_text')} 
                                                          </div>                  
                                              </div>

                                              <div className='col-md-6 col-12 head_left'>
                                                         
                                                        <div className="row">
                                                              <div className='col-md-6 col-12 head_left'>
                                                                       <div className="d-flex">
                                                                              <div className="flex-shrink-0">
                                                                                    <div className="number_lim">1</div>
                                                                              </div>
                                                                              <div className="flex-grow-1 ms-3">
                                                                                  <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                  </span>
                                                                              </div>
                                                                      </div>
                                                                 
                                                              </div>
                                                              <div className='col-md-6 col-12 head_left'>
                                                                       <div className="d-flex">
                                                                              <div className="flex-shrink-0">
                                                                                    <div className="number_lim">6</div>
                                                                              </div>
                                                                              <div className="flex-grow-1 ms-3">
                                                                                  <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                  </span>
                                                                              </div>
                                                                      </div>
   

                                                              </div>
                                                        </div>
                                                        <div className="row">
                                                              <div className='col-md-6 col-12 head_left'>
                                                                        <div className="d-flex">
                                                                              <div className="flex-shrink-0">
                                                                                    <div className="number_lim">2</div>
                                                                              </div>
                                                                              <div className="flex-grow-1 ms-3">
                                                                                  <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                  </span>
                                                                              </div>
                                                                      </div>

                                                              </div>
                                                              <div className='col-md-6 col-12 head_left'>
                                                                     <div className="d-flex">
                                                                              <div className="flex-shrink-0">
                                                                                    <div className="number_lim">7</div>
                                                                              </div>
                                                                              <div className="flex-grow-1 ms-3">
                                                                                  <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                  </span>
                                                                              </div>
                                                                      </div>

                                                              </div>
                                                        </div>
                                                        <div className="row">
                                                                      <div className='col-md-6 col-12 head_left'>
                                                                                      <div className="d-flex">
                                                                                            <div className="flex-shrink-0">
                                                                                                  <div className="number_lim">3</div>
                                                                                            </div>
                                                                                            <div className="flex-grow-1 ms-3">
                                                                                                <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                                </span>
                                                                                            </div>
                                                                                    </div>

                                                                            </div>
                                                                            <div className='col-md-6 col-12 head_left'>
                                                                                  <div className="d-flex">
                                                                                            <div className="flex-shrink-0">
                                                                                                  <div className="number_lim">8</div>
                                                                                            </div>
                                                                                            <div className="flex-grow-1 ms-3">
                                                                                                <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                                </span>
                                                                                            </div>
                                                                                    </div>

                                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                                          <div className='col-md-6 col-12 head_left'>
                                                                                          <div className="d-flex">
                                                                                                <div className="flex-shrink-0">
                                                                                                      <div className="number_lim">4</div>
                                                                                                </div>
                                                                                                <div className="flex-grow-1 ms-3">
                                                                                                    <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                                    </span>
                                                                                                </div>
                                                                                        </div>

                                                                                </div>
                                                                                <div className='col-md-6 col-12 head_left'>
                                                                                      <div className="d-flex">
                                                                                                <div className="flex-shrink-0">
                                                                                                      <div className="number_lim">9</div>
                                                                                                </div>
                                                                                                <div className="flex-grow-1 ms-3">
                                                                                                    <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                                    </span>
                                                                                                </div>
                                                                                        </div>

                                                                                </div>
                                                        </div>
                                                        <div className="row">
                                                                          <div className='col-md-6 col-12 head_left'>
                                                                                          <div className="d-flex">
                                                                                                <div className="flex-shrink-0">
                                                                                                      <div className="number_lim">5</div>
                                                                                                </div>
                                                                                                <div className="flex-grow-1 ms-3">
                                                                                                    <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                                    </span>
                                                                                                </div>
                                                                                        </div>

                                                                                </div>
                                                                                <div className='col-md-6 col-12 head_left'>
                                                                                      <div className="d-flex">
                                                                                                <div className="flex-shrink-0">
                                                                                                      <div className="number_lim">10</div>
                                                                                                </div>
                                                                                                <div className="flex-grow-1 ms-3">
                                                                                                    <span className="text_min">This is some content from a media component. You can replace this with any content and adjust it as needed.
                                                                                                    </span>
                                                                                                </div>
                                                                                        </div>

                                                                                </div>
                                                        </div>
                                                    
                                                          
                                              </div>
                                    </div>

                        </div>
                        <style>

                                {`  
                                           .zona_beneficio{
                                                margin-top:80px;
                                                padding:50px;
                                                border-radius:24px;
                                           }

                                           .zona_beneficio .flex-grow-1{
                                               padding-bottom:5px;
                                           }

                                            .zona_beneficio .text_min{
                                                 padding:0;
                                           }

                                           .number_lim{
                                              padding:5px;
                                               font-weight:700;
                                           }

                                `}

                        </style>  


    </div>
  )
}

export default Beneficio