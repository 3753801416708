import React from 'react'
import { useTranslation } from 'react-i18next'
import left  from './../assets/images/coins_left.svg';
import right  from './../assets/images/coins_right.svg';


const Ini_publi = () => {
    
    const [t, i18n] = useTranslation('global');


  return (
    <div>
               <section className="par tamano">

                      <main className="container p-1">
                           
                           <div className='row'>
                                        <div className="col-3  text-left "> 
                                                <div className='img_coin_l'>
                                                       <img src={left} className="img-fluid "/> 
                                               </div>
                                        </div>
                                        <div className="col-6  text-center ubica_text">{t('earn_cry')} <br></br>
                                                    <button className="btn btn_orange text-bold"> {t('here')} </button>
                                        </div>
                                        <div className="col-3   ">
                                                   <div className='img_coin_l text-right'>
                                                       <img src={right} className="img-fluid "/>
                                                    </div>
                                         </div>
                            </div>
                        </main>
                  
               </section>



               <style>
                     {`  
                          .tamano{
                              height:200px;
                              background:#c8ecda;
                          }
                              .ubica_text{
                                  margin-top:80px;
                                  font-size:24px;
                                  font-weight:600;
                              }

                              .img-pub{
                                 width:70%;
                              }

                                           .img_coin{
                                                
                                              width:70%;
                                               margin-left:-30px;
                                               margin-top:10px;
                                            }

                                            .img_coin_l{
                                            width:70%;
                                                 margin-left:30px;
                                                margin-top:20px;
                                            }

                              @media only screen and (max-width : 900px) {

                                        .tamano{
                                           height:120px;
                                        }

                                        .img-pub{
                                             width:100%;
                                        }

                                        .ubica_text{
                                            margin-top:12px;
                                            font-size:16px;
                                            font-weight:600;
                                            padding-right:10px;
                                        }
                                        
                                        .img_coin{
                                                 margin-top:20px;
                                                 margin-left:-20px;
                                                 width:95px;
                                        }

                                        .img_coin_l{
                                                 margin-top:25px;
                                                 margin-left:10px;
                                                 text-right;
                                                 width:95px;
                                        }
    
                            }
                     `}  
               </style>

    </div>
  )
}

export default Ini_publi