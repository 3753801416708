import React ,{useEffect, useState}  from 'react'
import {color} from "./../common/theme";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import rutas from './../setup';
import {gets} from './../common/server';
import { redes } from '../common/redes';
   

const Form_exchange = ()=>{
        
      const [t, i18n]                    = useTranslation("global");
      const [from, setFrom]              = useState('0.00');
      const [to, setTo]                  = useState('0.00');
      const [tokens, setTokens]          = useState([]);
      const [token, setToken]            = useState('');
      const [img_from, setImg_from]      = useState('');
      const [name_from, setName_from]    = useState('');
      const [fixed_from, setFixed_from]  = useState('');
      const [img_to, setImg_to]          = useState('');
      const [name_to, setName_to]        = useState('');
      const [id_from, setId_from]        = useState('');
      const [id_to,   setId_to]          = useState('');

      const cargar_data = (data)=>{
            console.log(data[0])
            setTokens(data);
            setImg_from(data[0].icon);
            setName_from(data[0].simbol);
            setId_from(data[0].id);
            setImg_to(data[0].icon);
            setName_to(data[0].simbol);
            setId_to(data[0].id);
      }


      function red_id(valor){
            let res = "";
            switch(valor){
                  case 1:  res = "TRON"; break;
                  case 2: res = "BSC"; break;
            }
            return res;
      }

      
      useEffect(() => {
            gets(rutas.tokens).then((valor)=>{
                      cargar_data(valor);
           });
     },[])


      const listItems = tokens.map((data) =>
              <li key={data.id} > 
                   <a href="#" className='dropdown-item'>
                        <img src={data.icon} className='img_cripto' /> 
                        <span className="cripto_list">{data.simbol}</span> 
                        <br></br>
                        <span className="text_red"><small> {red_id(data.red)}</small> </span>
                   </a>
              </li>
       );


  

      


  return (
    <div>
            <div className='box_exchange'>
                          
                     <div className='box-header'>
                                <div className="row " >

                                      <div className="col-6 text-center zona_exchange ">
                                              <span className="text-bold">{t("exchangecrypto")}</span>
                                      </div>

                                      <div className="col-6 text-center zona_sellbuy">
                                              <span className="text-bold">{t("sellbuy")} </span>
                                      </div>

                                </div>
                     </div>

                     <div className='box-body'>
                               
                                  <div className="from_exchange ">

                                         <div className="row">
                                              <div className="col-md-9 col-8">
                                                  
                                                        <div className="title">{t("from")}</div>

                                                        <div className="box-input">
                                                                <input className="form-control" value={from}  id="from" 
                                                                onChange={(e)=>setFrom(e.target.value)}
                                                                name ="from" />
                                                        </div>
                                              </div>

                                            <div className="col-md-3 col-4 select " >

                                                 
                                                                            <div className="nav-item dropdown ">


                                                                                    <div className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                               <img src={img_from}  className='img-fluid img_cripto'/>
                                                                                                <span className="name_cripto">{name_from}</span> 
                                                                                    </div>

                                                                                 
                                                                                    <ul className="dropdown-menu ubi" aria-labelledby="navbarDropdown">
                                                                                            {listItems}
                                                                                         {/*}  <li><a  className="dropdown-item" href="#" >{t('spain')}</a></li>
                                                                                           <li><a  className="dropdown-item" href="#">{t('english')}</a></li> */}
                                                                                   
                                                                                     </ul>
                                                                              </div>

                                    
                                                 
                                            </div>
                                        </div>


                                  </div>
                                  <div className="fee">
                                         <div className="titulo">{t("fixedrate")}: <span className="rate"> </span></div>
                                  </div>



                                  <div className="from_exchange">

                                            <div className="row">
                                              <div className="col-9">
                                                   
                                                        <div className="title">{t("to")}</div>
                                                                    <div className="box-input">
                                                                            <input className="form-control" value={to}
                                                                            onChange={(e)=>setTo(e.target.value)}
                                                                               id="from" name ="from" />
                                                                    </div>
                                                </div>

                                            <div className="col-3 select"> 
                                                     <div className="nav-item dropdown ">

                                                            <div className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src={img_to}  className='img-fluid img_cripto'/>
                                                                        <span className="name_cripto">{name_to}</span> 
                                                            </div>


                                                            <ul className="dropdown-menu ubi" aria-labelledby="navbarDropdown">
                                                                  {listItems}
                                                            </ul>
                                                            </div>

                                            </div>
                                        </div>

                                  </div>

                                  <div className="btn_exchange">
                                    
                                        <div className="d-grid gap-2">
                                                   <Link to="" className="btn btn_orange btn-large">{t('exchange')}</Link> 
                                        </div>
                                         
                                  </div>        
                    </div>
            </div>

             <style> 
                      {`               .ubi{
                                               margin-left:-100px;
                                        }
                                               
                                               .box_exchange .dropdown-toggle::after{
                                                     margin-top:5px;
                                                     margin-left:5px;
                                               }

                                        .name_cripto{
                                           font-size:18px;
                                           font-weight:600px;
                                        }  

                                        .text_red{
                                             font-size:14px;
                                        }

                                        .img_cripto{
                                           margin-top:-5px;
                                        width:30px; padding-right:7px;}
                      
                                        .text-bold{
                                               font-weight:600;
                                         }
                                        .part1, part2{
                                            display:inline-block;
                                         }

                                        .form-control{
                                                outline: none !important;
                                                color:#161616;
                                                font-weight:bold;
                                                font-size:20px;
                                                margin-top:-12px;;
                                         }
                                         .fee .titulo{
                                             font-size:12px;
                                             margin-left:8px;
                                          }

                                          .select{
                                              background:${color.GREEN};
                                             border-top-right-radius:12px;
                                             border-bottom-right-radius:12px;
                                             padding-top:10px;
                                             margin:0 auto;
                                             text-align:center;
                                          }
                                          .box_exchange{
                                               border-radius:12px;
                                               background:${color.GREEN_BOX};
                                          }

                                          .btn_orange{
                                             border-radius:8px;
                                          }

                                          .from_exchange .title{
                                          margin-bottom:0;
                                          padding-bottom:0;
                                              margin-left:10px;
                                              font-size:12px;
                                              color:#585858;
                                              
                                          }

                                          .box-body{
                                              padding:60px;
                                              padding-top:80px;
                                              padding-bottom:80px;
                                          }

                                          .from_exchange{  
                                             margin-bottom:30px;
                                             border-radius:12px;
                                             background:${color.GREEN_FORM};  
                                              padding-left:10px;
                                          }

                                          .from_exchange input{
                                                background:none !important;
                                                border:none;
                                                outline: none;
                                          }

                                          .from_exchange input:hover{
                                                       border:none !important;
                                          }
                                        
                                          .box_exchange .row{
                                                padding-right:12px;
                                          }

                                          .zona_exchange{
                                                padding:10px;
                                                position:relative;
                                          }

                                          .zona_exchange::before{
                                                            content:"";
                                                            position:absolute;
                                                            top:0;
                                                            right:0;
                                                            margin-left:80px;
                                                            border-top:44px solid ${color.GREEN_LIGHT};
                                                            border-left:60px solid transparent;
                                                            z-index:999;
                                          }
                                      

                                          .zona_sellbuy{
                                                padding:10px;
                                                background:${color.GREEN_LIGHT};
                                                border-top-right-radius:12px;
                                                
                                          }

                                          @media only screen and (max-width : 900px) {

                                                        .name_cripto{
                                                                  font-size:12px;
                                                            }

                                                            .img_cripto{
                                                                  margin-top:-2px;
                                                                  width:24px; padding-right:7px;
                                                            }

                                                             .box_exchange .dropdown-toggle::after{
                                                                      
                                                                        margin-left:5px;
                                                
                                                               }
                      
                                                
                                          }
                      `} 
             </style>


    </div>
  )
}

export default Form_exchange